import {ServiceService} from "ellipse/service/ServiceService";
import {BaseRequestObject} from "ellipse/objects/BaseRequestObject";
import {BaseResultObject} from "ellipse/objects/BaseResultObject";
import {BaseObject} from "ellipse/objects/BaseObject";
import {UnitTestIgnore} from "ellipse/test/UnitTestIgnore";

@UnitTestIgnore()
export class IServerInformationManager {

	private server: ServiceService;

	constructor(retry: number = 0, host: string | undefined = undefined) {
		this.server = new ServiceService(retry, host);
	}

	public fileServicePort = () : Promise<IServerInformationManager.FileServicePortResult> => {
		let promise = new Promise<IServerInformationManager.FileServicePortResult>((resolve, reject) => {
			let listener = function(data : any) {
				let r: IServerInformationManager.FileServicePortResult = new IServerInformationManager.FileServicePortResult();
				r.init(data);
				resolve(r);
			}
			let params : any = [];
			this.server.request(listener, 'tools.ellipse.service.information.IServerInformationManager', '{61a3ded8-847b-48dc-8de5-3e5057b694de}', params);
		});
		return promise;
	}

	public ping_Ltools_ellipse_service_information_PingResult = () : Promise<IServerInformationManager.PingResult> => {
		let promise = new Promise<IServerInformationManager.PingResult>((resolve, reject) => {
			let listener = function(data : any) {
				let r: IServerInformationManager.PingResult = new IServerInformationManager.PingResult();
				r.init(data);
				resolve(r);
			}
			let params : any = [];
			this.server.request(listener, 'tools.ellipse.service.information.IServerInformationManager', '{cfc8f651-0319-4e9f-9ebc-ce33de071548}', params);
		});
		return promise;
	}

	public ping_Ltools_ellipse_service_information_PingRequest_Ltools_ellipse_service_information_PingResult = (request : IServerInformationManager.PingRequest) : Promise<IServerInformationManager.PingResult> => {
		let promise = new Promise<IServerInformationManager.PingResult>((resolve, reject) => {
			let listener = function(data : any) {
				let r: IServerInformationManager.PingResult = new IServerInformationManager.PingResult();
				r.init(data);
				resolve(r);
			}
			let params : any = [request];
			this.server.request(listener, 'tools.ellipse.service.information.IServerInformationManager', '{81fb663e-b02e-43b0-b30d-e7688fe216c9}', params);
		});
		return promise;
	}

	public ping_Ltools_ellipse_service_information_PingAnonRequest_Ltools_ellipse_service_information_PingResult = (request : IServerInformationManager.PingAnonRequest) : Promise<IServerInformationManager.PingResult> => {
		let promise = new Promise<IServerInformationManager.PingResult>((resolve, reject) => {
			let listener = function(data : any) {
				let r: IServerInformationManager.PingResult = new IServerInformationManager.PingResult();
				r.init(data);
				resolve(r);
			}
			let params : any = [request];
			this.server.request(listener, 'tools.ellipse.service.information.IServerInformationManager', '{8c5debb1-f0bf-47a0-abe0-a70cd870f397}', params);
		});
		return promise;
	}

	public publicKey = () : Promise<IServerInformationManager.PublicKeyResult> => {
		let promise = new Promise<IServerInformationManager.PublicKeyResult>((resolve, reject) => {
			let listener = function(data : any) {
				let r: IServerInformationManager.PublicKeyResult = new IServerInformationManager.PublicKeyResult();
				r.init(data);
				resolve(r);
			}
			let params : any = [];
			this.server.request(listener, 'tools.ellipse.service.information.IServerInformationManager', '{906e91aa-1a10-48ab-9230-1160a988d92b}', params);
		});
		return promise;
	}

	public serverVersion = (request : IServerInformationManager.ServerVersionRequest) : Promise<IServerInformationManager.ServerVersionResult> => {
		let promise = new Promise<IServerInformationManager.ServerVersionResult>((resolve, reject) => {
			let listener = function(data : any) {
				let r: IServerInformationManager.ServerVersionResult = new IServerInformationManager.ServerVersionResult();
				r.init(data);
				resolve(r);
			}
			let params : any = [request];
			this.server.request(listener, 'tools.ellipse.service.information.IServerInformationManager', '{212b2e8c-ec09-4abd-9216-72a6d480caf4}', params);
		});
		return promise;
	}

	public serverTime = () : Promise<IServerInformationManager.ServerTimeResult> => {
		let promise = new Promise<IServerInformationManager.ServerTimeResult>((resolve, reject) => {
			let listener = function(data : any) {
				let r: IServerInformationManager.ServerTimeResult = new IServerInformationManager.ServerTimeResult();
				r.init(data);
				resolve(r);
			}
			let params : any = [];
			this.server.request(listener, 'tools.ellipse.service.information.IServerInformationManager', '{60b67bc6-b40b-4d3f-849e-fbd1d149f2ef}', params);
		});
		return promise;
	}

}

export namespace IServerInformationManager {

	export class FileServicePortResult extends BaseResultObject {

		public javaType = () : string => { return "tools.ellipse.service.information.FileServicePortResult"; }

		public port: number | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.port = this.typeNumber(this.port);
		}

	}

	export class PingResult extends BaseResultObject {

		public javaType = () : string => { return "tools.ellipse.service.information.PingResult"; }

		public serverId: string | undefined = undefined;

		public timestamp: Date | undefined = undefined;

		public portNative: number | undefined = undefined;

		public portFile: number | undefined = undefined;

		public portHttp: number | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.serverId = this.typeString(this.serverId);
			this.timestamp = this.typeDate(this.timestamp);
			this.portNative = this.typeNumber(this.portNative);
			this.portFile = this.typeNumber(this.portFile);
			this.portHttp = this.typeNumber(this.portHttp);
		}

	}

	export class PingRequest extends BaseRequestObject {

		public javaType = () : string => { return "tools.ellipse.service.information.PingRequest"; }

		public includePortInfo: boolean | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.includePortInfo = this.typeBoolean(this.includePortInfo);
		}

	}

	export class PingAnonRequest extends BaseRequestObject {

		public javaType = () : string => { return "tools.ellipse.service.information.PingAnonRequest"; }

		public includePortInfo: boolean | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.includePortInfo = this.typeBoolean(this.includePortInfo);
		}

	}

	export class PublicKeyResult extends BaseResultObject {

		public javaType = () : string => { return "tools.ellipse.service.information.PublicKeyResult"; }

		public key: IServerInformationManager.CertificatePublicKey | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.key = this.typeObject(()=>new IServerInformationManager.CertificatePublicKey(), this.key);
		}

	}

	export class CertificatePublicKey extends BaseObject {

		public javaType = () : string => { return "tools.ellipse.pki.objects.CertificatePublicKey"; }

		public key: string | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.key = this.typeString(this.key);
		}

	}

	export class ServerVersionResult extends BaseResultObject {

		public javaType = () : string => { return "tools.ellipse.service.information.ServerVersionResult"; }

		public serverVersion: string | undefined = undefined;

		public frameworkVersion: string | undefined = undefined;

		public serverRunningSince: string | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.serverVersion = this.typeString(this.serverVersion);
			this.frameworkVersion = this.typeString(this.frameworkVersion);
			this.serverRunningSince = this.typeString(this.serverRunningSince);
		}

	}

	export class ServerVersionRequest extends BaseRequestObject {

		public javaType = () : string => { return "tools.ellipse.service.information.ServerVersionRequest"; }

	}

	export class ServerTimeResult extends BaseResultObject {

		public javaType = () : string => { return "tools.ellipse.service.information.ServerTimeResult"; }

		public dateTime: Date | undefined = undefined;

		applyTypes(){
			super.applyTypes();
			this.dateTime = this.typeDate(this.dateTime);
		}

	}

}